<template>
  <div v-if="isStockAdmin">
    <DailyReport :isStock="true" />
  </div>
  <div v-else>
    <CustomerCount />
    <DailyReport v-if="isDailyReport" :isStock="false" />
    <TotalAgencies />
    <CustomerSource />
  </div>
</template>

<script>
import CustomerCount from "./customerCount/Index.vue";
import DailyReport from "./dailyReport/Index.vue";
import TotalAgencies from "./totalAgencies/Index.vue";
import CustomerSource from "./customerSource/Index.vue";

export default {
  name: "Dashboard",
  components: {
    CustomerCount,
    DailyReport,
    TotalAgencies,
    CustomerSource,
  },
  computed: {
    isDailyReport() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser &&
            [this.$const.ROLES.SuperAdmin, this.$const.ROLES.Owner].includes(
              authUser.role
            )
        : false;
    },
    isStockAdmin() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser && [this.$const.ROLES.StockAdmin].includes(authUser.role)
        : false;
    },
  },
};
</script>
