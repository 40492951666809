<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cil-user" />
          {{ $user.getters.authUser.fullName }}
        </span>
        <template v-if="timeBox.label || timeBox.subLabel">
          |
          <CIcon name="cil-calendar" />
          <span v-if="timeBox.label">
            {{ timeBox.label }}
          </span>
          <span v-if="timeBox.subLabel">
            {{ timeBox.subLabel }}
          </span>
        </template>
        <div class="card-header-actions">
          <CDropdown
            color="transparent p-0"
            :caret="false"
            placement="bottom-end"
            size="sm"
          >
            <template #toggler-content>
              <CIcon name="cil-settings" custom-classes="c-icon text-dark" />
            </template>
            <CDropdownItem
              v-for="(time, j) in timeList"
              :key="`time-${j}`"
              :class="{
                active:
                  timeBox &&
                  time.fromDate == timeBox.fromDate &&
                  time.toDate == timeBox.toDate,
              }"
              @click="timeBox = time"
            >
              {{ time.label }}
            </CDropdownItem>
            <CDropdownItem @click="showingRange = true">
              Chọn thời gian
            </CDropdownItem>
          </CDropdown>
        </div>
      </CCardHeader>
      <CCardBody>
        <div v-if="loading">
          <CSpinner color="info" size="sm" class="ml-2" />
          Đang tải...
        </div>
        <CRow v-else-if="countList && countList.length">
          <CCol
            v-for="countBox in countList"
            :key="`wd-${countBox.id}`"
            sm="6"
            lg="3"
          >
            <CWidgetDropdown
              :color="countBox.color"
              :header="$func.addCommas(countBox.agency)"
              :text="countBox.title.toUpperCase()"
            >
              <template #footer>
                <div class="m-4 font-italic font-weight-bold">
                  Cá nhân: {{ $func.addCommas(countBox.personal) }}
                </div>
              </template>
            </CWidgetDropdown>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <date-range :showing.sync="showingRange" :timeBox.sync="timeBox" />
  </div>
</template>

<script>
import DateRange from "@/components/DateRange.vue";
export default {
  components: { DateRange },
  name: "CustomerCount",
  data() {
    let timeList = [
      {
        label: `Hôm nay`,
        subLabel: this.$moment().format("DD/MM/YYYY"),
        fromDate: this.$moment()
          .startOf("day")
          .toISOString(),
        toDate: this.$moment()
          .endOf("day")
          .toISOString(),
      },
      {
        label: `Hôm qua`,
        subLabel: this.$moment()
          .add(-1, "days")
          .format("DD/MM/YYYY"),
        fromDate: this.$moment()
          .add(-1, "days")
          .startOf("day")
          .toISOString(),
        toDate: this.$moment()
          .add(-1, "days")
          .endOf("day")
          .toISOString(),
      },
      {
        label: `Tuần này`,
        fromDate: this.$moment()
          .startOf("week")
          .toISOString(),
        toDate: this.$moment()
          .endOf("week")
          .toISOString(),
      },
      {
        label: `Tuần trước`,
        fromDate: this.$moment()
          .add(-1, "weeks")
          .startOf("week")
          .toISOString(),
        toDate: this.$moment()
          .add(-1, "weeks")
          .endOf("week")
          .toISOString(),
      },
      {
        label: `Tháng này`,
        fromDate: this.$moment()
          .startOf("month")
          .toISOString(),
        toDate: this.$moment()
          .endOf("month")
          .toISOString(),
      },
      {
        label: `Tháng trước`,
        fromDate: this.$moment()
          .add(-1, "months")
          .startOf("month")
          .toISOString(),
        toDate: this.$moment()
          .add(-1, "months")
          .endOf("month")
          .toISOString(),
      },
    ];
    return {
      timeList,
      showingRange: false,
      timeBox: timeList[0],
      loading: false,
      countList: null,
    };
  },
  mounted() {
    this.loadCustomerCount(this.timeBox);
  },
  watch: {
    timeBox: {
      deep: true,
      handler(val) {
        this.loadCustomerCount(val);
      },
    },
  },
  methods: {
    async loadCustomerCount(val) {
      if (val && val.fromDate && val.toDate) {
        this.loading = true;
        let resp = await this.$http.get(`Dashboard/CustomerCount`, {
          params: {
            fromDate: val.fromDate,
            toDate: val.toDate,
          },
        });
        if (resp && resp.status == 200) {
          this.countList = resp.data;
        }
        this.loading = false;
      }
    },
  },
};
</script>
