<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="labels.map((_) => `${$const.CALENDAR_TYPES[viewType]} ${_}`)"
  />
</template>

<script>
import { CChartLine } from "@coreui/vue-chartjs";
import { hexToRgba } from "@coreui/utils/src";

export default {
  components: {
    CChartLine,
  },
  props: ["viewType", "sources", "sourceCharts", "colors"],
  computed: {
    labels() {
      let sourceCharts = this.sourceCharts;
      return [...new Set(sourceCharts.map((_) => _.label))];
    },

    defaultDatasets() {
      let sourceCharts = this.sourceCharts;
      return [...new Set(sourceCharts.map((_) => _.source))].map((_, i) => {
        let source = this.sources.find((s) => s.Value == _);
        return {
          label: source ? source.Text : _,
          backgroundColor: hexToRgba(this.colors[i], 10),
          borderColor: this.colors[i],
          pointHoverBackgroundColor: this.colors[i],
          borderWidth: 2,
          data: this.labels.map((label) => {
            let item = sourceCharts.find(
              (sc) => sc.source == _ && sc.label == label
            );
            return item ? item.visit : 0;
          }),
        };
      });
    },
    defaultOptions() {
      let max =
        Math.max.apply(
          Math,
          this.sourceCharts.map((_) => _.visit)
        ) + 1;
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(max / 5),
                max: max,
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };
    },
  },
};
</script>
