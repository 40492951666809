<template>
  <div>
    <CCard accent-color="warning">
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cil-calendar" />
          Báo biểu hàng ngày
        </span>
        <template v-if="reportDate">
          |
          {{ $moment(reportDate).format("DD/MM/yyyy") }}
        </template>
        <div class="card-header-actions">
          <CLink
            class="card-header-action btn-minimize"
            @click="isCollapsed = !isCollapsed"
          >
            <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
          </CLink>
        </div>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="400">
        <CCardBody>
          <CRow>
            <CCol md="6" sm="5" v-if="isAdminAndOwner">
              <div class="form-group">
                <div class="form-row">
                  <label class="col-form-label col-4 text-right">
                    Kho hàng
                  </label>
                  <v-select
                    v-model="warehouseId"
                    :options="warehouses"
                    :reduce="(p) => p.Id"
                    label="Name"
                    placeholder="Kho hàng"
                    class="col-8"
                  />
                </div>
              </div>
            </CCol>
            <CCol md="4" sm="3">
              <div role="group" class="form-group">
                <div class="form-row">
                  <label class="col-form-label col-4 text-right">
                    Ngày báo biểu:
                  </label>
                  <div class="col-8">
                    <datetime
                      type="date"
                      v-model="reportDate"
                      format="dd/MM/yyyy"
                      input-class="form-control"
                      value-zone="local"
                    ></datetime>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol md="2" sm="3" class="text-right">
              <CButton color="info" class="mr-2 mb-3" @click="search">
                <CIcon name="cil-search" custom-classes="c-icon m-0" />
                Xem
              </CButton>
              <CButton color="primary" class="mr-2 mb-3" @click="printDaily">
                <CIcon name="cil-print" custom-classes="c-icon m-0" />
                In
              </CButton>
            </CCol>
          </CRow>
          <div v-if="loading" class="text-center">
            <CSpinner color="info" size="sm" class="ml-2" />
            Đang tải...
          </div>
          <CRow class="daily-report" id="print">
            <CCol md="12">
              <template v-if="!isStock">
                <h4 class="text-center mt-2">
                  BÁO BIỂU HÀNG NGÀY
                </h4>
                <h5 v-if="reportTitle" class="text-center">
                  {{ reportTitle }}
                </h5>
                <table
                  class="table table-sm table-striped table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th>MĐH</th>
                      <th>Khách hàng</th>
                      <th>Trải nghiệm</th>
                      <th>Sản phẩm</th>
                      <th>Giá bán</th>
                      <th>Cọc</th>
                      <th>Ngày</th>
                      <th>Doanh thu</th>
                      <th>NVBH</th>
                    </tr>
                  </thead>
                  <tbody v-if="orders && orders.length">
                    <tr v-for="order in orders" :key="`order-${order.Id}`">
                      <td>#{{ order.OrderNumber }}</td>
                      <td>
                        {{ $const.TITLES[order.Customer.Title] }}
                        {{ order.Customer.Name }}
                      </td>
                      <td>
                        {{
                          order.Visit.BuyingExp
                            ? $const.BUYING_EXPERIENCES_TEXT[
                                order.Visit.BuyingExp
                              ]
                            : ""
                        }}
                      </td>
                      <td>
                        <div>
                          <div
                            v-for="(product, i) in order.OrderDetails"
                            :key="`product-${i}`"
                          >
                            -
                            {{
                              (product.Price
                                ? ""
                                : "Tặng " + product.Qty + " ") +
                                product.ProductName
                            }}
                          </div>
                        </div>
                      </td>
                      <td>
                        {{
                          $func.addCommas(
                            order.OrderDetails.reduce(
                              (acc, cur) => acc + cur.Price,
                              0
                            )
                          )
                        }}
                      </td>
                      <td>{{ $func.addCommas(order.Deposit) }}</td>
                      <td>
                        <div>
                          Ngày đặt:
                          {{ $moment(order.CreatedAt).format("DD/MM/YYYY") }}
                        </div>
                        <div v-if="order.ActualDeliveryDate">
                          Ngày giao:
                          {{
                            $moment(order.ActualDeliveryDate).format(
                              "DD/MM/YYYY"
                            )
                          }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            order.OrderPayments && order.OrderPayments.length
                          "
                        >
                          <div
                            v-for="(payment, i) in order.OrderPayments"
                            :key="`payment-${i}`"
                          >
                            {{
                              $const.PAYMENT_TYPES_TEXT[payment.PaymentType]
                            }}:
                            {{ $func.addCommas(payment.Amount) }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>{{ order.Seller.Name }}</div>
                        <div
                          class="small"
                          v-if="order.OrderShareds && order.OrderShareds.length"
                        >
                          (Chia bill TH {{ order.OrderShareds[0].SharedType }})
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="100%" class="text-center">
                        Không có dữ liệu doanh thu...
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="2">
                        Doanh thu đầu ngày (tháng
                        {{ $moment(reportDate).format("MM/YYYY") }}):
                      </th>
                      <th colspan="2">
                        {{
                          turnover.beforeAmount
                            ? $func.addCommas(turnover.beforeAmount)
                            : 0
                        }}
                      </th>
                      <th></th>
                      <th colspan="2">Doanh thu trong ngày:</th>
                      <th>
                        {{
                          orders && orders.length && turnover.amount
                            ? $func.addCommas(turnover.amount)
                            : 0
                        }}
                      </th>
                      <th></th>
                    </tr>
                    <tr>
                      <th colspan="2">
                        Doanh thu lũy kế:
                      </th>
                      <th colspan="2">
                        {{
                          $func.addCommas(
                            (turnover.beforeAmount
                              ? turnover.beforeAmount
                              : 0) + (turnover.amount ? turnover.amount : 0)
                          )
                        }}
                      </th>
                      <th></th>
                      <th colspan="2"></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>

                <h5 class="text-center">
                  Chi phí ngày
                </h5>
                <table
                  class="table table-sm table-striped table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>
                        Mã chi phí
                      </th>
                      <th>
                        Tên chi phí
                      </th>
                      <th>
                        Số tiền chi
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="expenses && expenses.length">
                    <tr v-for="(row, i) in expenses" :key="`expense-${i}`">
                      <td>{{ i + 1 }}</td>
                      <td>
                        {{ row.Expense.Code }}
                      </td>
                      <td>
                        {{ row.Expense.Name }}
                      </td>
                      <td>
                        {{ $func.addCommas(row.Amount) }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="100%" class="text-center">
                        Không có dữ liệu chi phí...
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="3" class="text-right">
                        Tổng tiền chi trong ngày:
                      </th>
                      <th>
                        {{
                          expenses && expenses.length
                            ? $func.addCommas(
                                expenses.reduce(
                                  (acc, cur) => acc + cur.Amount,
                                  0
                                )
                              )
                            : 0
                        }}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </template>

              <h4 class="text-center mt-2">
                HÀNG HÓA XUẤT NHẬP HÀNG NGÀY
              </h4>
              <table
                class="table table-sm table-striped table-hover table-bordered"
              >
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên Sản Phẩm</th>
                    <th>ĐVT</th>
                    <th>Tồn đầu</th>
                    <th>SL Nhập</th>
                    <th>SL Xuất</th>
                    <th>Tồn cuối</th>
                    <th>Ghi chú</th>
                  </tr>
                </thead>
                <tbody v-if="stocks && stocks.length">
                  <tr v-for="(stock, i) in stocks" :key="`stock-${i}`">
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ stock.productName }}
                    </td>
                    <td>
                      {{ stock.unit }}
                    </td>
                    <td>
                      {{ $func.addCommas(stock.preStock) }}
                    </td>
                    <td>
                      {{ $func.addCommas(stock.qtyImport) }}
                    </td>
                    <td>{{ $func.addCommas(stock.qtyExport) }}</td>
                    <td>
                      {{ $func.addCommas(stock.nextStock) }}
                    </td>
                    <td>
                      {{ stock.note }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="100%" class="text-center">
                      Không có dữ liệu tồn kho...
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="stocks && stocks.length">
                  <tr>
                    <th colspan="3"></th>
                    <th>
                      {{
                        $func.addCommas(
                          stocks.reduce((acc, cur) => acc + cur.preStock, 0)
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $func.addCommas(
                          stocks.reduce((acc, cur) => acc + cur.qtyImport, 0)
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $func.addCommas(
                          stocks.reduce((acc, cur) => acc + cur.qtyExport, 0)
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $func.addCommas(
                          stocks.reduce((acc, cur) => acc + cur.nextStock, 0)
                        )
                      }}
                    </th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: ["isStock"],
  components: { datetime: Datetime },
  data() {
    return {
      isCollapsed: true,
      loading: false,
      reportDate: this.$moment().toISOString(),
      warehouses: [],
      warehouseId: 0,
      orders: [],
      stocks: [],
      reportTitle: "",
      turnover: {},
      expenses: [],
    };
  },
  computed: {
    isAdminAndOwner() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser &&
            (authUser.role == this.$const.ROLES.SuperAdmin ||
              authUser.role == this.$const.ROLES.Owner ||
              authUser.role == this.$const.ROLES.StockAdmin)
        : false;
    },
    userAgencyIds() {
      let authUser = this.$user.getters.authUser;
      return authUser ? authUser.agencyIds : null;
    },
  },
  async mounted() {
    this.warehouses = await this.loadWarehouses(
      this.userAgencyIds,
      this.isStock
    );
  },
  methods: {
    async search() {
      let reportDate = this.reportDate;
      let warehouseId = this.warehouseId;
      if (!reportDate || !warehouseId) {
        alert("Vui lòng chọn Kho hàng và Ngày báo biểu!");
      }
      this.loading = true;

      let warehouse = this.warehouses.find((_) => _.Id == warehouseId);
      if (warehouse && warehouse.AgencyId && !this.isStock) {
        this.orders = await this.getOrdersByDate(
          reportDate,
          warehouse.AgencyId
        );
        this.turnover = await this.getTurnover(reportDate, warehouse.AgencyId);
        this.expenses = await this.getExpenses(reportDate, warehouse.AgencyId);
      } else {
        this.orders = [];
        this.turnover = {};
        this.expenses = [];
      }

      this.stocks = await this.getStockByDate(reportDate, warehouseId);
      this.reportTitle = warehouse
        ? `${warehouse.Name} ${this.$moment(reportDate).format("DD/MM/YYYY")}`
        : "";

      this.loading = false;
    },
    async loadWarehouses(agencyIds, isStock) {
      let warehouses = [];
      let active = this.$const.STATUS.Active;
      let filter = [`Status eq ${active}`];
      if (agencyIds && agencyIds != "all") {
        if (isStock) {
          filter.push(`Id in (${agencyIds})`);
        } else {
          filter.push(`AgencyId in (${agencyIds})`);
        }
      }
      let resp = await this.$http.get("odata/InventoryWarehouse", {
        params: {
          $top: 1000,
          $filter: filter.join(` and `),
          $select: "Id,Name,AgencyId",
          $orderby: "Name",
        },
      });
      if (resp && resp.status == 200) {
        warehouses = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
            AgencyId: _.AgencyId,
          };
        });
      }
      return warehouses;
    },

    async getOrdersByDate(reportDate, agencyId) {
      let result = [];
      if (reportDate && agencyId) {
        let active = this.$const.STATUS.Active;
        let filters = [];

        filters.push(
          `Status in (${this.$const.ORDER_STATUS.New}, ${this.$const.ORDER_STATUS.Completed})`
        );

        let createdFrom = this.$moment(reportDate).toISOString();
        let createdTo = this.$moment(reportDate)
          .endOf("day")
          .toISOString();

        filters.push(
          `OrderPayments/any(f:f/CreatedAt ge ${createdFrom} and f/CreatedAt le ${createdTo})`
        );
        let paymentFilter = `CreatedAt ge ${createdFrom} and CreatedAt le ${createdTo}`;

        filters.push(
          `(Visit/AgencyId eq ${agencyId} or OrderShareds/any(f:f/SharedAgencyId eq ${agencyId}))`
        );
        let resp = await this.$http.get(`odata/Order`, {
          params: {
            $top: 1000,
            $filter: filters.join(` and `),
            //$select: "Id,Name",
            $expand:
              `OrderPayments($select=PaymentType,Amount;$filter=${paymentFilter}), ` +
              `OrderDetails($orderby=Price desc), ` +
              `Customer($select=Title,Name,LastName,Mobile), ` +
              `Seller($select=Name), ` +
              `Visit($select=BuyingExp), ` +
              `OrderShareds($count=true;$top=1;$select=SharedType;$expand=SharedAgency($select=Name),SharedUser($select=Name);$filter=Status eq ${active})`,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value;
        }
      }
      return result;
    },

    async getTurnover(reportDate, agencyId) {
      let result = {};
      if (reportDate && agencyId) {
        let startDateOfMonth = this.$moment(reportDate)
          .startOf("month")
          .toISOString();
        let fromDate = this.$moment(reportDate).toISOString();

        let toDate = this.$moment(reportDate)
          .endOf("day")
          .toISOString();

        let resp = await this.$http.get(`Dashboard/DailyReportTurnover`, {
          params: {
            agencyId,
            startDateOfMonth,
            fromDate,
            toDate,
          },
        });
        if (resp && resp.status == 200 && resp.data.length) {
          result = resp.data[0];
        }
      }
      return result;
    },

    async getExpenses(reportDate, agencyId) {
      let result = [];
      if (reportDate && agencyId) {
        let filters = [];
        //let active = this.$const.STATUS.Active;
        //filters.push(`Status eq ${active}`);

        let createdFrom = this.$moment(reportDate).toISOString();
        let createdTo = this.$moment(reportDate)
          .endOf("day")
          .toISOString();
        filters.push(
          `ExpenseDate ge ${createdFrom} and ExpenseDate le ${createdTo}`
        );

        filters.push(`AgencyId eq ${agencyId}`);

        let resp = await this.$http.get(`odata/AgencyExpense`, {
          params: {
            $select: `Id,Amount`,
            $expand: `Expense($select=Code,Name)`,
            $filter: filters.join(` and `),
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data.value;
        }
      }
      return result;
    },

    async getStockByDate(reportDate, warehouseId) {
      let result = [];
      if (reportDate && warehouseId) {
        let fromDate = this.$moment(reportDate).toISOString();

        let toDate = this.$moment(reportDate)
          .endOf("day")
          .toISOString();

        let resp = await this.$http.get(`Dashboard/DailyReportInventory`, {
          params: {
            warehouseId,
            fromDate,
            toDate,
          },
        });
        if (resp && resp.status == 200) {
          result = resp.data;
        }
      }
      return result;
    },

    printDaily() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);
      WinPrint.focus();
      window.setTimeout(() => {
        WinPrint.print();
        WinPrint.document.close();
        WinPrint.close();
      }, 1000);
    },
  },
};
</script>
