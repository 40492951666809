<template>
  <div>
    <CRow>
      <CCol md="12">
        <transition name="fade">
          <CCard accent-color="success">
            <CCardHeader>
              <div class="form-row">
                <div class="col-md-10">
                  <div class="form-row" v-if="editing">
                    <div class="col-md-2">
                      <select
                        class="form-control form-control-sm"
                        v-model="reportType"
                      >
                        <option
                          v-for="item in Object.keys(reportTypes)"
                          :key="`reportItem-${item}`"
                          :value="item"
                        >
                          {{ reportTypes[item] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-1">
                      <button
                        class="btn btn-sm btn-secondary"
                        @click="editing = false"
                      >
                        Hủy
                      </button>
                    </div>
                  </div>
                  <a
                    v-else
                    href="javascript:"
                    class="text-primary"
                    @click="editing = true"
                  >
                    <CIcon name="cil-chart" />
                    {{ reportTypes[reportType] }}
                  </a>
                </div>
                <div class="col-md-2 text-right">
                  <div class="card-header-actions">
                    <CLink
                      class="card-header-action btn-minimize"
                      @click="isCollapsed = !isCollapsed"
                    >
                      <CIcon
                        :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`"
                      />
                    </CLink>
                  </div>
                </div>
              </div>
            </CCardHeader>
            <CCollapse :show="!isCollapsed" :duration="400">
              <CCardBody>
                <CRow>
                  <CCol
                    md="4"
                    sm="3"
                    v-if="reportType == 'CustomerVisit' && isAgencyFilter"
                  >
                    <div class="form-group">
                      <div class="form-row">
                        <label class="col-form-label col-4 text-right">
                          Cửa hàng
                        </label>
                        <v-select
                          v-model="agencyId"
                          :options="agencies"
                          :reduce="(p) => p.Id"
                          label="Name"
                          placeholder="Cửa hàng"
                          class="col-8"
                        />
                      </div>
                    </div>
                  </CCol>
                  <CCol
                    md="4"
                    sm="3"
                    v-if="reportType == 'CustomerVisitAgencies'"
                  >
                    <CSelect
                      label="Kênh KH liên hệ"
                      placeholder="Kênh KH liên hệ"
                      :value.sync="source"
                      :options="[
                        { value: null, label: 'Tất cả' },
                        ...miscs
                          .filter((_) => _.Type == 'Source')
                          .sort((a, b) => (a.OrderNo >= b.OrderNo ? 1 : -1))
                          .map((_) => {
                            return { value: _.Value, label: _.Text };
                          }),
                      ]"
                      :horizontal="{
                        label: 'col-4 text-right',
                        input: 'col-8',
                      }"
                    />
                  </CCol>
                  <CCol md="3" sm="3">
                    <div role="group" class="form-group">
                      <div class="form-row">
                        <label class="col-form-label col-4 text-right">
                          Từ ngày:
                        </label>
                        <div class="col-8">
                          <datetime
                            type="date"
                            v-model="fromDate"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            value-zone="local"
                          ></datetime>
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol md="3" sm="3">
                    <div role="group" class="form-group">
                      <div class="form-row">
                        <label class="col-form-label col-4 text-right">
                          Đến ngày:
                        </label>
                        <div class="col-8">
                          <datetime
                            type="date"
                            v-model="toDate"
                            format="dd/MM/yyyy"
                            input-class="form-control"
                            value-zone="local"
                          ></datetime>
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol md="2" sm="3" class="text-right">
                    <CButton color="info" class="mr-2 mb-3" @click="search">
                      <CIcon name="cil-search" custom-classes="c-icon m-0" />
                      Tìm kiếm
                    </CButton>
                  </CCol>
                </CRow>

                <CDataTable
                  class="mb-0 table-outline"
                  hover
                  :items="tableItems"
                  :fields="tableFields"
                  head-color="light"
                  size="sm"
                  striped
                  no-sorting
                  sorter
                >
                  <td
                    slot="agencyId"
                    class="text-right"
                    slot-scope="{ item, index }"
                  >
                    <span :class="item.style">{{ index + 1 }}.</span>
                  </td>
                  <td slot="agencyName" class="text-left" slot-scope="{ item }">
                    <span :class="item.style">{{ item.agencyName }}</span>
                  </td>
                  <td
                    slot="totalAmount"
                    slot-scope="{ item }"
                    class="text-right"
                  >
                    <span :class="item.style" class="font-weight-bold">
                      {{ $func.addCommas(item.totalAmount) }}
                    </span>
                  </td>
                  <td slot="qty" slot-scope="{ item }" class="text-right">
                    {{ $func.addCommas(item.qty) }}
                  </td>
                  <td
                    slot="qtyResponse"
                    slot-scope="{ item }"
                    class="text-right"
                  >
                    {{
                      item.qtyResponse ? $func.addCommas(item.qtyResponse) : "0"
                    }}
                  </td>
                  <td
                    slot="rateResponse"
                    slot-scope="{ item }"
                    class="text-left"
                    :class="colorRate(item.rateResponse)"
                  >
                    {{ (item.rateResponse ? item.rateResponse : 0).toFixed(2) }}
                    %
                  </td>
                  <td slot="qtyNeed" slot-scope="{ item }" class="text-right">
                    {{ $func.addCommas(item.qtyNeed) }}
                  </td>
                  <td
                    slot="rateNeed"
                    slot-scope="{ item }"
                    class="text-left text-nowrap"
                    :class="colorRate(item.rateNeed)"
                  >
                    {{ item.rateNeed.toFixed(2) }} %
                  </td>
                  <td slot="qtyMobile" slot-scope="{ item }" class="text-right">
                    {{ $func.addCommas(item.qtyMobile) }}
                  </td>
                  <td
                    slot="rateMobile"
                    slot-scope="{ item }"
                    class="text-left text-nowrap"
                    :class="colorRate(item.rateMobile)"
                  >
                    {{ item.rateMobile.toFixed(2) }} %
                  </td>
                  <td
                    slot="qtyMobileNeed"
                    slot-scope="{ item }"
                    class="text-right"
                  >
                    {{ $func.addCommas(item.qtyMobileNeed) }}
                  </td>
                  <td
                    slot="rateMobileNeed"
                    slot-scope="{ item }"
                    class="text-left text-nowrap"
                    :class="colorRate(item.rateMobileNeed)"
                  >
                    {{ item.rateMobileNeed.toFixed(2) }} %
                  </td>
                  <td
                    slot="qtyCounter"
                    slot-scope="{ item }"
                    class="text-right"
                  >
                    {{ $func.addCommas(item.qtyCounter) }}
                  </td>
                  <td
                    slot="rateCounter"
                    slot-scope="{ item }"
                    class="text-left text-nowrap"
                    :class="colorRate(item.rateCounter)"
                  >
                    {{ item.rateCounter.toFixed(2) }} %
                  </td>
                  <td slot="qtyBill" slot-scope="{ item }" class="text-right">
                    {{ $func.addCommas(item.qtyBill) }}
                  </td>
                  <td
                    slot="rateBill"
                    slot-scope="{ item }"
                    class="text-left text-nowrap"
                    :class="colorRate(item.rateBill)"
                  >
                    {{ item.rateBill.toFixed(2) }} %
                  </td>
                  <td
                    slot="totalBill"
                    slot-scope="{ item }"
                    class="text-right text-nowrap"
                    :class="{
                      'text-success': item.totalBill,
                      'text-danger': !item.totalBill,
                    }"
                  >
                    {{ $func.addCommas(item.totalBill) }}
                  </td>
                  <template v-slot:footer="{ itemsAmount }">
                    <tfoot v-if="reportType == 'CustomerVisitAgencies'">
                      <tr>
                        <th colspan="2" class="text-center">
                          TỔNG:
                        </th>
                        <th class="text-right">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.qty,
                                0
                              )
                            )
                          }}
                        </th>
                        <th class="text-right">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.qtyNeed,
                                0
                              )
                            )
                          }}
                        </th>
                        <th class="text-nowrap">
                          {{
                            (
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.rateNeed,
                                0
                              ) / itemsAmount
                            ).toFixed(2)
                          }}
                          %
                        </th>
                        <th class="text-right">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.qtyMobile,
                                0
                              )
                            )
                          }}
                        </th>
                        <th class="text-nowrap">
                          {{
                            (
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.rateMobile,
                                0
                              ) / itemsAmount
                            ).toFixed(2)
                          }}
                          %
                        </th>
                        <th class="text-right">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.qtyMobileNeed,
                                0
                              )
                            )
                          }}
                        </th>
                        <th class="text-nowrap">
                          {{
                            (
                              tableItems.reduce(
                                (partialSum, a) =>
                                  partialSum + a.rateMobileNeed,
                                0
                              ) / itemsAmount
                            ).toFixed(2)
                          }}
                          %
                        </th>
                        <th class="text-right">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.qtyCounter,
                                0
                              )
                            )
                          }}
                        </th>
                        <th class="text-nowrap">
                          {{
                            (
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.rateCounter,
                                0
                              ) / itemsAmount
                            ).toFixed(2)
                          }}
                          %
                        </th>
                        <th class="text-right">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.qtyBill,
                                0
                              )
                            )
                          }}
                        </th>
                        <th class="text-nowrap">
                          {{
                            (
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.rateBill,
                                0
                              ) / itemsAmount
                            ).toFixed(2)
                          }}
                          %
                        </th>
                        <th class="text-right text-nowrap">
                          {{
                            $func.addCommas(
                              tableItems.reduce(
                                (partialSum, a) => partialSum + a.totalBill,
                                0
                              )
                            )
                          }}
                        </th>
                      </tr>
                    </tfoot>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCollapse>
          </CCard>
        </transition>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

export default {
  components: { datetime: Datetime },
  data() {
    let reportTypes = this.$const.DASHBOARD_TYPES_TEXT;
    return {
      isCollapsed: true,
      fromDate: this.$moment().toISOString(),
      toDate: this.$moment().toISOString(),
      tableItems: [],
      editing: false,
      reportType: Object.keys(reportTypes)[0],
      reportTypes,
      agencies: [],
      agencyId: 0,
      miscs: [],
      source: null,
    };
  },
  mounted() {
    if (!this.isAgencyFilter) {
      Object.keys(this.reportTypes).forEach((_) => {
        if (_ != "CustomerVisit") {
          delete this.reportTypes[_];
        }
      });
      this.reportType = Object.keys(this.reportTypes)[0];
      this.agencyId = this.userAgencyId;
    }
  },
  watch: {
    async reportType(val) {
      if (val) {
        this.editing = false;
        this.tableItems = [];

        if (val == "CustomerVisit" && this.isAgencyFilter) {
          this.agencies = await this.loadAgencies();
        } else if (val == "CustomerVisitAgencies") {
          this.miscs = await this.loadMiscs();
        }
      }
    },
  },
  computed: {
    tableFields() {
      let reportType = this.reportType;
      switch (reportType) {
        case "OrderTotalAgencies":
          return [
            { key: "agencyId", label: "STT", sorter: false },
            { key: "agencyName", label: "Tên Cửa hàng" },
            { key: "totalAmount", label: "Doanh thu", _classes: "text-right" },
          ];
        case "CustomerVisit":
          return [
            { key: "agencyId", label: "STT", sorter: false },
            { key: "source", label: "Nguồn kênh liên hệ" },
            { key: "qty", label: "Khách liên hệ", _classes: "text-right" },
            { key: "qtyNeed", label: "KH có nhu cầu", _classes: "text-right" },
            { key: "rateNeed", label: "Tỷ lệ", _classes: "text-left" },
            {
              key: "qtyMobile",
              label: "Khách cho số ĐT",
              _classes: "text-right",
            },
            { key: "rateMobile", label: "Tỷ lệ", _classes: "text-left" },
            {
              key: "qtyMobileNeed",
              label: "SĐT KH có nhu cầu",
              _classes: "text-right",
            },
            { key: "rateMobileNeed", label: "Tỷ lệ", _classes: "text-left" },
            {
              key: "qtyCounter",
              label: "Khách ghé quầy/tại nhà",
              _classes: "text-right",
            },
            { key: "rateCounter", label: "Tỷ lệ", _classes: "text-left" },
            { key: "qtyBill", label: "Chốt Bill", _classes: "text-right" },
            { key: "rateBill", label: "Tỷ lệ", _classes: "text-left" },
            { key: "totalBill", label: "Doanh số", _classes: "text-right" },
          ];
        case "CustomerVisitAgencies": {
          let results1 = [
            { key: "agencyId", label: "STT", sorter: false },
            { key: "agencyName", label: "Tên Cửa hàng" },
            { key: "qty", label: "Khách liên hệ", _classes: "text-right" },
          ];
          let results2 = [
            { key: "qtyNeed", label: "KH có nhu cầu", _classes: "text-right" },
            { key: "rateNeed", label: "Tỷ lệ", _classes: "text-left" },
            {
              key: "qtyMobile",
              label: "Khách cho số ĐT",
              _classes: "text-right",
            },
            { key: "rateMobile", label: "Tỷ lệ", _classes: "text-left" },
            {
              key: "qtyMobileNeed",
              label: "SĐT KH có nhu cầu",
              _classes: "text-right",
            },
            { key: "rateMobileNeed", label: "Tỷ lệ", _classes: "text-left" },
            {
              key: "qtyCounter",
              label: "Khách ghé quầy/tại nhà",
              _classes: "text-right",
            },
            { key: "rateCounter", label: "Tỷ lệ", _classes: "text-left" },
            { key: "qtyBill", label: "Chốt Bill", _classes: "text-right" },
            { key: "rateBill", label: "Tỷ lệ", _classes: "text-left" },
            { key: "totalBill", label: "Doanh số", _classes: "text-right" },
          ];
          return this.source == "FbAd_removed"
            ? [
                ...results1,
                {
                  key: "qtyResponse",
                  label: "Khách phản hồi",
                  _classes: "text-right",
                },
                { key: "rateResponse", label: "Tỷ lệ", _classes: "text-left" },
                ...results2,
              ]
            : [...results1, ...results2];
        }
        default:
          return [];
      }
    },
    userAgencyId() {
      let authUser = this.$user.getters.authUser;
      return authUser &&
        authUser.agencyIds &&
        authUser.agencyIds != "all" &&
        !authUser.agencyIds.includes(",")
        ? parseInt(authUser.agencyIds)
        : null;
    },
    isAgencyFilter() {
      let authUser = this.$user.getters.authUser;
      return authUser
        ? authUser.role == this.$const.ROLES.SuperAdmin ||
            authUser.role == this.$const.ROLES.Owner
        : false;
    },
  },
  methods: {
    async search() {
      let reportType = this.reportType;
      let params = {
        fromDate: this.$moment(this.fromDate)
          .startOf("day")
          .toISOString(),
        toDate: this.$moment(this.toDate)
          .endOf("day")
          .toISOString(),
      };
      if (reportType == "CustomerVisit") {
        if (!this.agencyId) {
          alert("Vui lòng chọn một cửa hàng cần xem!");
          return;
        } else {
          params.agencyId = this.agencyId;
        }
      } else if (reportType == "CustomerVisitAgencies" && this.source) {
        reportType += `/${this.source}`;
      }

      let resp = await this.$http.get(`Dashboard/${reportType}`, {
        params,
      });
      if (resp != null && resp.status == 200) {
        this.tableItems = resp.data;
      } else {
        this.tableItems = [];
      }
    },
    async loadAgencies() {
      let agencies = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Agency", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name",
          //isAll: true,
        },
      });
      if (resp && resp.status == 200) {
        agencies = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
          };
        });
      }
      return agencies;
    },

    colorRate(value) {
      return {
        "text-success": value >= 40,
        "text-danger": value < 40,
      };
    },

    async loadMiscs() {
      let types = ["Source"];
      let result = [];
      let resp = await this.$http.get("odata/Misc", {
        params: {
          $top: 200,
          $select: "Value,Text,OrderNo,Type",
          $filter: `Status eq ${
            this.$const.STATUS.Active
          } and Type in ('${types.join("','")}')`,
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Value: _.Value,
            Text: _.Text,
            OrderNo: _.OrderNo,
            Type: _.Type,
          };
        });
      }
      return result;
    },
  },
};
</script>
