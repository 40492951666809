<template>
  <CCard accent-color="info">
    <CCardBody>
      <CRow>
        <CCol sm="5">
          <h4 class="card-title mb-0">Nguồn KH liên hệ</h4>
          <div class="small text-muted">Thống kê {{ selected.title }}</div>
        </CCol>
        <CCol sm="7">
          <!-- class="d-none d-md-block" -->
          <CButtonGroup class="float-right mr-3">
            <CButton
              color="outline-secondary"
              v-for="(item, key) in viewTypes"
              :key="key"
              class="mx-0"
              :pressed="item.value === selected.value ? true : false"
              @click="selected = item"
            >
              {{ $const.CALENDAR_TYPES[item.value] }}
            </CButton>
          </CButtonGroup>
        </CCol>
      </CRow>
      <Chart
        :viewType="selected.value"
        :sources="sources"
        :sourceCharts="sourceCharts"
        :colors="colors"
        style="height:300px;margin-top:40px;"
      />
    </CCardBody>
    <CCardFooter>
      <Summary
        :sources="sources"
        :sourceCharts="sourceCharts"
        :colors="colors"
      />
    </CCardFooter>
  </CCard>
</template>

<script>
import Chart from "./Chart.vue";
import Summary from "./Summary.vue";

export default {
  components: {
    Chart,
    Summary,
  },
  data() {
    let now = new Date();
    let viewTypes = [
      {
        value: "Day",
        title: `Tháng ${now.getMonth() + 1}`,
      },
      {
        value: "Month",
        title: `Năm ${now.getFullYear()}`,
      },
      {
        value: "Year",
        title: `Tất cả các năm`,
      },
    ];
    return {
      selected: viewTypes[0],
      viewTypes,
      sources: [],
      sourceCharts: [],
    };
  },
  computed: {
    colors() {
      return this.sourceCharts.map(() => {
        return `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, 0)}`;
      });
      // return [
      //   "#4dbd74",
      //   "#20a8d8",
      //   "#f86c6b",
      //   "#212529",
      //   "#ffc107",
      //   "#0d6efd",
      //   "#6c757d",
      //   "#4B0082",
      //   "#DB7093",
      //   "#A52A2A",
      //   "#8B008B",
      //   "#2F4F4F",
      //   "#D71868",
      //   "#D71868",
      //   "#D71868",
      //   "#D71868",
      //   "#D71868",
      //   "#D71868",
      // ]; //.map((_) => getStyle(_) || "#4dbd74");
    },
  },
  async mounted() {
    this.sources = await this.getMiscList();
    this.sourceCharts = await this.loadSourceChart(this.selected.value);
  },
  watch: {
    selected: {
      deep: true,
      async handler(val) {
        this.sourceCharts = await this.loadSourceChart(val.value);
      },
    },
  },
  methods: {
    async getMiscList() {
      let typeFilter = "Type in ('Source')"; // , 'PriceRange', 'Comment', 'Interest', 'NextStep'
      let statusFilter = `Status eq ${this.$const.STATUS.Active}`;
      let resp = await this.$http.get(`odata/Misc`, {
        params: {
          $filter: `${typeFilter} and ${statusFilter}`,
        },
      });
      if (resp && resp.status == 200) {
        return resp.data.value;
      }
      return [];
    },

    async loadSourceChart(viewType) {
      if (viewType) {
        let resp = await this.$http.get(`Dashboard/CustomerSourceChart`, {
          params: { viewType: viewType },
        });
        if (resp && resp.status == 200) {
          return resp.data;
        }
      }
      return [];
    },
  },
};
</script>
