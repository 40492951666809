<template>
  <CRow class="text-center">
    <CCol
      v-for="(item, i) in sourceData"
      :key="`progress-${i}`"
      md="2"
      sm="6"
      class="mb-sm-2 mb-0"
    >
      <div class="text-muted">{{ item.source }}</div>
      <strong>
        {{ $func.addCommas(item.visit) }}/{{ $func.addCommas(item.total) }} lượt
        ({{ item.value.toFixed(2) }}%)
      </strong>
      <div class="progress-xs mt-2 progress">
        <div
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuenow="item.value"
          class="progress-bar bg-success"
          :style="
            `width: ${item.value}%; background-color: ${item.color} !important;`
          "
        ></div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: ["sources", "sourceCharts", "colors"],
  computed: {
    sourceData() {
      return [...new Set(this.sourceCharts.map((_) => _.source))].map(
        (_, i) => {
          let source = this.sources.find((s) => s.Value == _);
          let visitSources = this.sourceCharts.filter((sc) => sc.source == _);

          let visit = visitSources.reduce(
            (total, currentValue) => total + currentValue.visit,
            0
          );
          let total = this.sourceCharts.reduce(
            (total, currentValue) => total + currentValue.visit,
            0
          );
          return {
            source: source ? source.Text : _,
            visit,
            total,
            value: (visit / total) * 100,
            color: this.colors[i],
          };
        }
      );
    },
  },
};
</script>
